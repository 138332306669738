//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import { getLanguageCode } from '@/i18n';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default {
  name: 'app-messages-show-page',
  props: ['openImg', 'otherUserId', 'user', 'isClosed'],
  data: () => ({
    standard: 0,
    audios: [],
    language: getLanguageCode(),
  }),
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      messages: 'complaint/messages',
      complaintsArr: 'complaint/complaintsArr',
    }),
    lang() {
      return localStorage.getItem('language');
    },
    selectedMessages() {
      const arr = this.messages;
      for (const a of arr) {
        if(a.sentAt==null){
          a.sentAt= firebase.firestore.Timestamp.now()
        }
      }
      return arr
    },
  },
  watch: {
    selectedMessages: {
      immediate: true,
      handler() {
        // this.doFetchMessageUser();
        this.scrollBottom();
      },
      deep: true,
    },

    otherUserId: {
      immediate: true,
      handler() {
        this.scrollBottom();
      },
    },
  },
  created() {
    this.scrollBottom();
  },
  methods: {
    ...mapActions({
      retrySendMessage: 'retrySendMessage',
      setChannelId: 'setChannelId',
      setIsRead: 'setIsRead',
      close: 'close',
    }),
    doClose() {
      this.close(this.otherUserId);
    },
    getMessageState(message) {
      if (message.isRead) {
        return '✓✓';
      }
      if (message.isLoading) {
        return '';
      }
      return '✓';
    },
    getStamp(date) {
      moment.locale(this.getLang());
      return moment(date).fromNow();
    },
    getLang() {
      return localStorage.getItem('language');
    },
    getAvatar(user) {
      if (
        user &&
        user.avatars &&
        user.avatars[0] &&
        user.avatars[0].publicUrl
      ) {
        return user.avatars[0].publicUrl;
      }
      if(user && user.avatar && user.avatar.publicUrl){
        return user.avatar.publicUrl
      }
      return 'https://firebasestorage.googleapis.com/v0/b/saryah-31e36.appspot.com/o/defaultAvatar%2FdefaultAvatar.svg?alt=media&token=cfff16d3-2ebd-414c-9aea-4295499369c8';
    },
    getDefaultAvatar() {
      return 'https://firebasestorage.googleapis.com/v0/b/saryah-31e36.appspot.com/o/defaultAvatar%2FdefaultAvatar.svg?alt=media&token=cfff16d3-2ebd-414c-9aea-4295499369c8';
    },
    scrollBottom() {
      setTimeout(() => {
        var container = document.querySelector(
          '#messages-container',
        );
        if (container) {
          container.scrollTop = container.scrollHeight;
          this.textMessage = null;
        }
      }, 10);
    },
    replaceTimestampToDate(timestamp) {
      return timestamp.toDate();
      // return moment().locale(this.language).format("dddd");
    },
    presenterDateTime(timestamp) {
      if(timestamp !=''){

        
      const dateTime = this.replaceTimestampToDate(
        timestamp,
      );
      const date = moment(dateTime)
        .locale(this.language)
        .format('MMM DD');
      const time = moment(dateTime)
        .locale(this.language)
        .format('hh:mm A');
      return this.language == 'ar'
        ? `الساعة ${time}  ${date} `
        : `${date} At ${time}`;
      }


      // return moment(dateTime).locale(this.language).format('hh:mm a')
    },
  },
};
