import { render, staticRenderFns } from "./complaint.vue?vue&type=template&id=1d0e4c1e&scoped=true&"
import script from "./complaint.vue?vue&type=script&lang=js&"
export * from "./complaint.vue?vue&type=script&lang=js&"
import style0 from "./complaint.vue?vue&type=style&index=0&lang=css&"
import style1 from "./complaint.vue?vue&type=style&index=1&id=1d0e4c1e&scoped=true&lang=css&"
import style2 from "./complaint.vue?vue&type=style&index=2&id=1d0e4c1e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d0e4c1e",
  null
  
)

export default component.exports
import {QCard,QCardSection,QForm,QInput,QBtn,QIntersection,QSpinnerBars,QDialog,QIcon,QImg,QField,QSpinner} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QForm,QInput,QBtn,QIntersection,QSpinnerBars,QDialog,QIcon,QImg,QField,QSpinner})
