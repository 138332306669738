import { render, staticRenderFns } from "./complaint-show-page.vue?vue&type=template&id=5cabf5de&scoped=true&"
import script from "./complaint-show-page.vue?vue&type=script&lang=js&"
export * from "./complaint-show-page.vue?vue&type=script&lang=js&"
import style0 from "./complaint-show-page.vue?vue&type=style&index=0&id=5cabf5de&lang=scss&scoped=true&"
import style1 from "./complaint-show-page.vue?vue&type=style&index=1&lang=css&"
import style2 from "./complaint-show-page.vue?vue&type=style&index=2&id=5cabf5de&scoped=true&lang=scss&"
import style3 from "./complaint-show-page.vue?vue&type=style&index=3&id=5cabf5de&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cabf5de",
  null
  
)

export default component.exports
import {QCardSection,QAvatar,QBanner,QChatMessage,QIcon,QSpinnerDots,QField,QDialog,QCard} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCardSection,QAvatar,QBanner,QChatMessage,QIcon,QSpinnerDots,QField,QDialog,QCard})
