var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column",staticStyle:{"max-width":"100%"}},[_c('q-card-section',{staticClass:"bg-messages text-white col-auto",staticStyle:{"width":"100%"},attrs:{"horizontal":""}},[_c('q-card-section',{attrs:{"avatar":""}},[_c('q-avatar',{staticClass:"avatar"},[(_vm.selectedMessages)?_c('img',{attrs:{"src":_vm.getAvatar(_vm.user)}}):_vm._e()])],1),_c('div',{staticClass:"col"},[_c('div',{staticClass:"text-chat-name q-pt-lg row"},[_c('span',[_vm._v(_vm._s(_vm.user.fullName || _vm.otherUserId))])]),_c('div',{staticClass:"text-about row q-pt-xs",class:_vm.lang === 'ar' ? 'justify-end' : '',staticStyle:{"direction":"ltr"}},[_vm._v(" "+_vm._s(_vm.user.phoneNumber || '')+" ")])])],1),_c('q-card-section',{ref:"chatArea",staticClass:"my-card-section-body col scroll",staticStyle:{"width":"100%","max-height":"47vh","position":"relative"},attrs:{"id":"messages-container"}},[(_vm.isClosed)?_c('q-banner',{staticClass:"text-white bg-red row justify-center",staticStyle:{"position":"sticky","z-index":"10"},attrs:{"inline-actions":""}},[_c('div',{staticClass:"row col-12 justify-center"},[_c('app-i18n',{attrs:{"code":"entities.complaint.closed"}})],1)]):_vm._e(),(_vm.selectedMessages)?_c('div',_vm._l((_vm.selectedMessages),function(message,key){return _c('div',{key:key,staticClass:"row"},[_c('div',{staticClass:"row col-12 justify-center text-center message-sentAt"},[_vm._v(" "+_vm._s(_vm.presenterDateTime(message.sentAt))+" ")]),_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"row col-12 q-pr-md",class:message.senderId != _vm.user.id 
              ? 'justify-end'
              : 'justify-start'},[(
                message.attachment &&
                  message.type === 'image'
              )?_c('div',{staticClass:"q-pl-md",class:message.senderId != _vm.user.id 
              ? 'justify-end'
              : 'justify-start'},[_c('q-chat-message',{class:message.senderId != _vm.user.id 
              ? 'justify-end'
              : 'justify-start',attrs:{"sent":message.senderId != _vm.user.id,"stamp":_vm.getMessageState(message),"text-color":"white","bg-color":"RahalBlue"},on:{"click":function($event){return _vm.openImg(message.attachment.publicUrl)}}},[_c('img',{class:message.senderId != _vm.user.id 
                      ? 'imgMessage col-3'
                      : 'col-3 imgMessage sent',staticStyle:{"margin":"0 !important","padding":"0"},attrs:{"src":message.attachment.publicUrl,"ratio":"1","spinner-color":"white"}})])],1):_vm._e()]),(
              message.attachment &&
                message.type == 'file'
            )?_c('div',{staticClass:"fileMessage row"},[_c('div',{staticClass:"fileMessage-container col-12"},[_c('div',{class:message.senderId == _vm.user.id 
                    ? 'message--container--sent '
                    : 'message--container'},[_c('div',{class:message.senderId == _vm.user.id
                      ? 'message--sent'
                      : 'message'},[_c('a',{staticClass:"attachLink",attrs:{"href":message.attachment.url,"target":"_blank"}},[_c('q-icon',{attrs:{"name":"fas fa-paperclip"}}),_c('span',{staticClass:"ellipsis"},[_vm._v(_vm._s(message.attachment.name))])],1)])])])]):_vm._e()]),_c('div',{staticClass:"row col-12 q-pr-md",class:message.senderId != _vm.user.id
              ? 'justify-end'
              : 'justify-start'}),(message.type=='text')?_c('div',{staticClass:"row col-12 q-pl-md",class:message.senderId != _vm.user.id
              ? 'justify-end'
              : 'justify-start'},[_c('q-chat-message',{staticClass:"message",attrs:{"text":[message.message],"isClosed":_vm.isClosed,"text-color":"white","bg-color":"RahalBlue","sent":message.senderId != _vm.user.id}})],1):_vm._e()])}),0):_vm._e(),_c('div',{staticClass:"row"},[(_vm.user && _vm.user.isTyping)?_c('div',{staticClass:"row col-12 justify-start q-pl-md"},[_c('q-chat-message',{staticClass:"message",attrs:{"text-color":"black","bg-color":"inputBackground"}},[_c('q-spinner-dots',{attrs:{"size":"2rem"}})],1)],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }