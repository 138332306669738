//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { i18n } from '@/vueI18n';
import messagesShowPage from '@/modules/complaint/components/complaint-show-page.vue';

export default {
  name: 'app-messages',
  props: ['isNew', 'isSmallScreen','otherUserId', 'ChangeState', 'data','user','isClosed'],
  components: {
    [messagesShowPage.name]: messagesShowPage,
  },
  data() {
    return {
      channel_state: undefined,
      isSubmit: false,
      isError: false,
      visibleMenu: false,
      img: [],
      file: [],
      attachment: [],
      AttachmentType: null,
      imgDialog: false,
      selectedImg: null,
      chunks: [],
      max: 1,
      pathImg: `/complaint/${this.otherUserId}/`,
      pathFile: `/complaint/${this.otherUserId}/`,
      Schema: { formats: ['zip'] },
      btnStop: false,
      isRec: false,
      seconds: 0,
      totalSeconds: 0,
      minutes: 0,
      textMessage: '',
      state: false,
      imgMenu: false,
      fileMenu: false,
      messagesContainer: [],
      otherUser: [],
      channelId: undefined,
      initRecorder: true,
      menu: false,
      timeOutVar: null,
      imageName: Math.floor((new Date()).getTime() / 1000)
      // messages: []
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      messages:'complaint/messages'
    }),
    selectedMessage(){
      if(this.otherUserId){
        return this.messages[this.otherUserId]
      }
      return []
    }
  },
  mounted() {
    this.scrollBottom();
  },
  methods: {
    uploadFromRequest(data) {
       console.log(data);
    },
    ...mapActions({
      setMessageError: 'setMessageError',
      setChannelId: 'setChannelId',
      sendMessage: 'sendMessage',
      init: 'init',
      // doFindById: 'iam/view/doFindById',
      doReply:'complaint/doReply',
      // doSendNotification:'complaint/doSendNotification',
    }),
    successUpload() {
      this.$vs.notify({
        color: 'success',
        title: 'Upload Success',
        text: 'Lorem ipsum dolor sit amet, consectetur',
      });
    },
    i18n(label) {
      return i18n(label);
    },
    async doSubmit() {
      let attach = false;
      if (!attach) {
        this.img.forEach((element) => {
          var regex = /(?:\.([^.]+))?$/;
          var extension = regex.exec(element.name)[1];  
          this.attachment.push({
            publicUrl: element.publicUrl,
            name: this.imageName + "." + extension,
          });
          // this.AttachmentType = 'IMAGE';
          attach = true;
          this.textMessage = null
        });
      }
      if (!attach) {
        this.file.forEach((element) => {
          this.attachment.push({
            publicUrl: element.publicUrl,
            name: this.imageName,
          });
          // this.AttachmentType = 'FILE';
          attach = true;
          this.textMessage = null
        });
      }
      this.isSubmit = true;
      this.isError = false;
      if (attach || this.textMessage) {
        this.imgMenu = false;
        this.fileMenu = false;
        // const tempId = `TEMP_${new Date().getTime()}`;
        const message = {
          id:this.otherUserId,
          message:this.textMessage || "",
          attachment: this.attachment.length?this.attachment[0]:{},
          type: this.attachment.length?"image":"text",
          // AttachmentType: this.AttachmentType,
          // TempID: tempId
        }
        this.attachment = [];
        this.img = [];
        this.file = [];
        // this.sendMessage(message)
        //   .then((data) => {
        //     this.isSubmit = false;

        //     this.scrollBottom();
        //     if (newChat) {
        //       setChannelId(data);
        //       changeNewChat();
        //     }
        //   })
        //   .catch((err) => {
        //      console.log(err);
        //     this.setMessageError({
        //       tempId,
        //       id: this.currentUser.id,
        //       otherUserId: this.otherUserId,
        //     });
        //   });
        await this.doReply({
        // data: {...message,tokens:this.user.deviceTokens || {}}
        data: message
      })
      // this.doSendNotification({
      //   data: {tokens:this.user.deviceTokens || {},id:this.otherUserId}
      // })
        this.scrollBottom();
      } else {
        this.isSubmit = false;

      }


      
      // const newChat = this.isNew;
      // const messageText = this.textMessage;
      // this.textMessage = null;
      // const changeNewChat = this.ChangeState;
      // const setChannelId = this.setChannelId;
      // let attach = false;
      // if (!attach) {
      //   this.img.forEach((element) => {
      //     this.attachment.push({
      //       url: element.publicUrl,
      //       name: element.name,
      //     });
      //     this.AttachmentType = 'IMAGE';
      //     attach = true;
      //   });
      // }
      // if (!attach) {
      //   this.file.forEach((element) => {
      //     this.attachment.push({
      //       url: element.publicUrl,
      //       name: element.name,
      //     });
      //     this.AttachmentType = 'FILE';
      //     attach = true;
      //   });
      // }
      // if (attach || messageText) {
      //   this.imgMenu = false;
      //   this.fileMenu = false;
      //   const tempId = `TEMP_${new Date().getTime()}`;
      //   const message = {
      //     message: messageText,
      //     userId: this.otherUserId,
      //     senderId: this.currentUser.id,
      //     attachment: this.attachment,
      //     AttachmentType: this.AttachmentType,
      //     TempID: tempId,
      //   };
      //   this.attachment = [];
      //   this.img = [];
      //   this.file = [];
      //   this.sendMessage(message)
      //     .then((data) => {
      //       this.isSubmit = false;

      //       this.scrollBottom();
      //       if (newChat) {
      //         setChannelId(data);
      //         changeNewChat();
      //       }
      //     })
      //     .catch((err) => {
      //        console.log(err);
      //       this.setMessageError({
      //         tempId,
      //         id: this.currentUser.id,
      //         otherUserId: this.otherUserId,
      //       });
      //     });
      //   this.scrollBottom();
      // } else {
      //   this.isSubmit = false;
      // }
    },
    toggleImgMenu() {
      if (this.imgMenu) {
        this.imgMenu = false;
      } else {
        this.fileMenu = false;
        if (this.isRec) this.recHandler(false);
        this.btnStop = false;
        setTimeout(() => {
          this.imgMenu = true;
        }, 3);
      }
      this.scrollBottom();
    },
    toggleFileMenu() {
      if (this.fileMenu) {
        this.fileMenu = false;
      } else {
        this.imgMenu = false;
        if (this.isRec) this.recHandler(false);
        this.btnStop = false;
        setTimeout(() => {
          this.fileMenu = true;
        }, 3);
      }
      this.scrollBottom();
    },
    openImg(attachment) {
      this.selectedImg = attachment;
      this.imgDialog = true;
    },

    scrollBottom() {
      setTimeout(() => {
        var container = document.querySelector(
          '#messages-container',
        );
        if (container) {
          container.scrollTop = container.scrollHeight;
          this.textMessage = null;
        }
      }, 100);
    },
    toggleMenu() {
      this.menu = !this.menu;
    },
  },
  async created() {
    // this.doFindById(this.otherUserId);
  },
  watch: {
    otherUserId: {
      immediate: true,
      handler() {
        this.textMessage = null;
      },
    },
  },
};
